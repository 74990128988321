define("funnelytics/routes/map-pro-oto/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "funnelytics/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: _environment.default['ember-simple-auth'].authenticationRoute,
    userDetails: Ember.inject.service()
  });

  _exports.default = _default;
});