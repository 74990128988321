define("funnelytics/services/cookies-service", ["exports", "lodash", "funnelytics/config/environment"], function (_exports, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AUTH_COOKIE_NAME = 'flx_auth';
  var COOKIE_PATH = '/';

  var _default = Ember.Service.extend({
    cookies: Ember.inject.service(),
    session: Ember.inject.service(),
    saveAuthToken: function saveAuthToken() {
      var isAdminLoginAsUser = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var dataAuth = _lodash.default.pick(this.get('session.data.authenticated'), ['id', 'access_token', 'refresh_token']);

      dataAuth['isAdminLoginAsUser'] = isAdminLoginAsUser;
      var value = JSON.stringify(dataAuth);
      var cookies = this.get('cookies');
      var isDevelopment = _environment.default.deployTarget !== 'production';
      var isLocal = _environment.default.nodeEnvironment === 'localhost';
      cookies.write(AUTH_COOKIE_NAME, value, {
        domain: isLocal ? '' : '.funnelytics.io',
        path: COOKIE_PATH,
        expires: new Date('Thu, 01 Jan 2038 00:00:00 UTC'),
        raw: true,
        secure: !isDevelopment
      });
    },
    restoreAuthToken: function restoreAuthToken(newSessionData) {
      var dataAuth = _lodash.default.pick(newSessionData, ['id', 'access_token', 'refresh_token', 'isAdminLoginAsUser']);

      var value = JSON.stringify(dataAuth);
      var cookies = this.get('cookies');
      var isDevelopment = _environment.default.deployTarget !== 'production';
      var isLocal = _environment.default.nodeEnvironment === 'localhost';
      cookies.write(AUTH_COOKIE_NAME, value, {
        domain: isLocal ? '' : '.funnelytics.io',
        path: COOKIE_PATH,
        expires: new Date('Thu, 01 Jan 2038 00:00:00 UTC'),
        raw: true,
        secure: !isDevelopment
      });
    },
    deleteAuthToken: function deleteAuthToken() {
      var cookies = this.get('cookies');
      var isDevelopment = _environment.default.deployTarget !== 'production';
      var isLocal = _environment.default.nodeEnvironment === 'localhost';
      var domain = isLocal ? '' : '.funnelytics.io';
      cookies.clear(AUTH_COOKIE_NAME, {
        domain: domain,
        path: COOKIE_PATH,
        secure: !isDevelopment
      });
      document.cookie = "".concat(AUTH_COOKIE_NAME, "=''; Domain=").concat(domain, "; Path=").concat(COOKIE_PATH, "; Expires=Thu, 01 Jan 1970 00:00:01 GMT;");
    },
    getFLSessionFromCookie: function getFLSessionFromCookie() {
      try {
        var allCookies = (document.cookie || '').split(';');

        if (!allCookies || !allCookies.length) {
          return null;
        }

        var fsCookie = allCookies.find(function (i) {
          return i.indexOf('_fs') !== -1;
        });

        if (!fsCookie) {
          return null;
        }

        var flSession = fsCookie.split('=')[1];
        return flSession;
      } catch (err) {
        return null;
      }
    },
    getFirstPromoterTrackId: function getFirstPromoterTrackId() {
      try {
        var allCookies = (document.cookie || '').split(';');

        if (!allCookies || !allCookies.length) {
          return null;
        }

        var fprTidCookieName = '_fprom_tid';
        var fprTidCookie = allCookies.find(function (i) {
          return i.indexOf(fprTidCookieName) !== -1;
        });

        if (!fprTidCookie) {
          return null;
        }

        var fprTid = fprTidCookie.split('=')[1].trim();
        return fprTid;
      } catch (err) {
        return null;
      }
    }
  });

  _exports.default = _default;
});