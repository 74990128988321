define("funnelytics/router", ["exports", "funnelytics/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),
    session: Ember.inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this.trackPageHelper();
    },
    trackPageHelper: function trackPageHelper() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url'),
            title = _this.getWithDefault('currentRouteName', 'unknown');

        Ember.get(_this, 'metrics').trackPage({
          page: page,
          title: title
        });
      });
    }
  });
  Router.map(function () {
    this.route('login');
    this.route('redirect');
    this.route('dashboard', function () {
      this.route('import');
      this.route('vault', function () {
        this.route('index', {
          path: '/'
        });
        this.route('view', {
          path: '/:id'
        });
      });
      this.route('funnels', function () {
        this.route('view', {
          path: 'view/:id'
        });
        this.route('unorganized');
        this.route('new');
      });
      this.route('settings', function () {
        this.route('account', function () {
          this.route('email-update', {
            path: 'email-update/:email_update_id'
          });
        });
        this.route('lookup', function () {
          this.route('user', {
            path: '/:id'
          });
        });
        this.route('my-subscriptions');
        this.route('cancel-subscriptions');
      });
      this.route('templates', function () {
        this.route('view', {
          path: 'view/:id'
        });
        this.route('settings', {
          path: 'settings/:id'
        });
      });
      this.route('users', function () {
        this.route('view', {
          path: ':id'
        });
      });
      this.route('projects', {
        path: 'workspaces'
      }, function () {
        this.route('project', {
          path: ':project_id'
        }, function () {
          this.route('clients');
          this.route('funnels', function () {
            this.route('upsell', function () {
              this.route('index', {
                path: '/:id'
              });
            });
          });
          this.route('integrations');
          this.route('settings');
          this.route('unorganized');
        });
        this.route('new');
        this.route('upgrade');
      });
      this.route('icons', function () {
        this.route('new', {
          path: '/:id'
        });
        this.route('edit', {
          path: '/edit/:id'
        });
        this.route('folders', function () {
          this.route('new');
          this.route('edit', {
            path: '/edit/:id'
          });
        });
      });
      this.route('pro'); // Redirect to order.pro

      this.route('upgrade'); // Redirect to order.upgrade

      this.route('vault-order'); // Redirect to order.upgrade

      this.route('yearly'); // Redirect to order.upgrade

      this.route('ignite'); // Redirect to order.upgrade

      this.route('review'); // Redirect to order.upgrade
    });
    this.route('share', {
      path: 'share/:id'
    });
    this.route('register');
    this.route('registered', {
      path: '/registered/:funnel'
    });
    this.route('reset', {
      path: '/reset/:id'
    });
    this.route('forgot');
    this.route('set-password', {
      path: '/set-password/:id'
    });
    this.route('order', function () {
      /**
       * order.pro REDIRECTS
       */
      this.route('clickfunnels-templates', function () {});
      this.route('marketer', function () {});
      this.route('pro-100k', function () {});
      this.route('pro-250k', function () {});
      this.route('pro-500k', function () {});
      this.route('quarterly', function () {});
      this.route('yearly', function () {
        this.route('checkout');
      });
      this.route('pro-1-project', function () {});
      this.route('pro-10-projects', function () {});
      this.route('pro-20-projects', function () {});
      this.route('pro-2-projects', function () {});
      this.route('upgrade', function () {});
      /**
       * \order.pro REDIRECTS
       */

      this.route('pro', {
        path: _environment.default.orderProPath
      }, function () {});
      this.route('offer');
      this.route('confirmation');
      this.route('vault', function () {
        this.route('lifetime');
        /**
         * order.vault REDIRECTS
         */

        this.route('upsell');
        this.route('checkout');
        this.route('offer');
        /**
         * \order.vault REDIRECTS
         */
      });
      /**
       * order.vault.lifetime REDIRECTS
       */

      this.route('vault-lifetime', function () {
        this.route('upsell');
      });
      /**
       * \order.vault.lifetime REDIRECTS
       */
    });
    this.route('library', function () {
      this.route('index', {
        path: '/'
      });
      this.route('share', {
        path: '/:id'
      });
    });
    this.route('map-pro-oto', function () {
      this.route('index', {
        path: '/'
      });
    }), this.route('503');
    this.route('404', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});